import { getData, postData } from "./common.service";

export async function searchDevices(
  text: string,
  skip: number = 0,
  limit: number = 10
) {
  const qs = {
    where: {
      or: [
        { imei: { like: `.*${text}.*`, options: "i" } },
        { deviceId: { like: `.*${text}.*`, options: "i" } },
      ],
      imei: { exists: true }
    },
    limit: limit,
    skip: skip,
  };

  return getData("/devices?filter=" + JSON.stringify(qs), {
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    sendTokenInHeader: true,
  }).then((res) => res.json());
}

export async function getDeviceGraphs(text: string, timestamp: Date) {
  const qs = {
    where: {
      and: [
        { timestamp: { gte: timestamp } },
        { deviceId: text }
      ]
    },
    order: "timestamp ASC"
  };

  return getData("/final-field-data?filter=" + JSON.stringify(qs), {
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    sendTokenInHeader: true,
  }).then((res) => res.json()).catch(err => console.log(err));

}

export async function getInventoryLocationsPaginated(text: string = "", skip: number = 0, limit: number = 20) {
  const qs = {
    where: {
      name: { like: `.*${text}.*`, options: 'i' }
    },
    limit: limit,
    skip: skip,
    fields: {
      id: true,
      name: true,
      stock:true
    }
  };
  return getData('/inventory-locations?filter=' + JSON.stringify(qs), {
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    sendTokenInHeader: true,
  }).then(data => data.json())
}

export async function getInventoryLocationById(text: string = "") {
  const qs = {
    where: {
      id: `${text}`
    },
    fields: {
      id: true,
      stock: true
    }
  };
  return getData('/inventory-locations?filter=' + JSON.stringify(qs), {
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    sendTokenInHeader: true,
  }).then(data => data.json())
}

export async function getInventoryItemsPaginated(text: string = "", skip: number = 0, limit: number = 20) {
  const qs = {
    where: {
      name: {
        inq: ['nero_single', 'nero_double', 'kairo', 'nero_single_rental', 'nero_double_rental', 'kairo_rental']
      }
    },
    limit: limit,
    skip: skip,
    order:['name ASC'],
    fields: {
      id: true,
      name: true
    }
  };
  return getData('/inventory-items?filter=' + JSON.stringify(qs), {
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    sendTokenInHeader: true,
  }).then(data => data.json())
}

export async function getInventoryItemsOfLocation(itemIds: string[]) {
  const qs = {
    where: {
      name: {
        inq: ['nero_single', 'nero_double', 'kairo', 'nero_single_rental', 'nero_double_rental', 'kairo_rental']
      },
      id:{inq: itemIds}
    },
    order:['name ASC'],
    fields: {
      id: true,
      name: true
    }
  };
  return getData('/inventory-items?filter=' + JSON.stringify(qs), {
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    sendTokenInHeader: true,
  }).then(data => data.json())
}

export async function deductInventory(data: {
  inventoryLocation: any, inventoryItem: any,
  count: number, farmUserId: string, plotId: string
}) {
  return postData('/inventory-movements', {
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    sendTokenInHeader: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: {
      date: new Date().toISOString(),
      quantity: data.count,
      status: "COMPLETED",
      comments: "from support app",
      inventoryItemId: data.inventoryItem.id,
      fromInventoryLocationId: data.inventoryLocation.id,
      farmUserId: data.farmUserId,
      plotId: data.plotId
    }
  });
}

export async function deductInventoryRental(data: {
  inventoryLocation: any, inventoryItem: any,
  count: number, farmUserId: string, plotId: string
}) {
  const subtractedFromMainStock = await postData('/inventory-movements', {
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    sendTokenInHeader: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: {
      date: new Date().toISOString(),
      quantity: data.count,
      type: "CONVERTED_TO_RENTAL",
      status: "COMPLETED",
      comments: "reduced from main stock from support app",
      inventoryItemId: data.inventoryItem.id,
      fromInventoryLocationId: data.inventoryLocation.id
    }
  });
  const addedAsRentalStock = await postData('/inventory-movements', {
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    sendTokenInHeader: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: {
      date: new Date().toISOString(),
      quantity: data.count,
      type: "CONVERTED_TO_RENTAL",
      status: "COMPLETED",
      comments: "added to rental stock from support app",
      inventoryItemId: data.inventoryItem.id + '_rental',
      toInventoryLocationId: data.inventoryLocation.id
    }
  });
  return postData('/inventory-movements', {
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    sendTokenInHeader: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: {
      date: new Date().toISOString(),
      quantity: data.count,
      status: "COMPLETED",
      comments: "from support app",
      inventoryItemId: data.inventoryItem.id + '_rental',
      fromInventoryLocationId: data.inventoryLocation.id,
      farmUserId: data.farmUserId,
      plotId: data.plotId
    }
  });
}

export async function addToRentalInventory(data: {
  inventoryLocation: any, inventoryItem: any,
  count: number, farmUserId: string, plotId: string
}) {
  return postData('/inventory-movements', {
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    sendTokenInHeader: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: {
      date: new Date().toISOString(),
      quantity: data.count,
      type: "RETURNED_TO_INVENTORY",
      status: "COMPLETED",
      comments: "returned from support app",
      inventoryItemId: data.inventoryItem.id,
      toInventoryLocationId: data.inventoryLocation.id,
      farmUserId: data.farmUserId,
      plotId: data.plotId
    }
  });
}

export async function postInventoryMovement(payload: any) {
  console.log("recieved: ", payload);
  const url = '/inventory-movements';
  return postData(url, {
    sendTokenInHeader: true,
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: {
      date: new Date().toISOString(),
      quantity: payload.quantity,
      status: "OPEN",
      comments: payload.comments,
      inventoryItemId: payload.inventoryItemId,
      toInventoryLocationId: payload.toInventoryLocationId,
      fromInventoryLocationId: payload.fromInventoryLocationId,
      userId: payload.userId,
      type: payload.type
    }
  })
}

export async function completeMovement(id:string) { 
 const url = `/inventory-movements/${id}/complete`;
 return getData(url, {
   sendTokenInHeader: true,
   basePath: process.env.REACT_APP_CORE_API_BASEPATH,
 })
}