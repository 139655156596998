import { Link, Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonHeader,
  IonToolbar,
  IonRouterOutlet,
  IonTitle,
  IonSplitPane,
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonMenuToggle,
  IonPage,
  IonLabel,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Network } from "@capacitor/network";
import Login from "./pages/Login";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import React, { useCallback, useEffect, useState } from "react";
import { handleErrors } from "./services/common.service";
import {
  canAccessCustomerData,
  getUserProfile,
  logoutUser,
} from "./services/user.service";
import MainTabs from "./pages/MainTabs";
import Profile from "./pages/Profile";
import "./App.css";
import MaintenanceList from "./pages/MaintenanceList";
import LeadList from "./pages/LeadList";
import TicketList from "./pages/TicketList";
import { useRecoilState } from "recoil";
import { userProfileState, zammadProfileState } from "./services/state.service";
import ReferralList from "./pages/ReferralList";
import FaultyPlots from "./pages/FaultyPlots";
import FormulaList from "./pages/FormulaList";
import PaymentQRCode from "./components/PaymentQRCode";
import GoogleMap from "./pages/GoogleMap";
import InteractionList from "./pages/InteractionList";
import OutlierPlotDetails from "./components/OutlierPlotDetails";
import PendingPaymentList from "./pages/PendingPaymentList";
import PlotSensorData from "./components/PlotSensorData";
import { saveToken } from "./services/firebase.service";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { useIonToast } from "@ionic/react";
import VoucherList from "./pages/VoucherList";
import PlotList from "./pages/PlotList";
import Plot from "./pages/Plot";
import ExpiredPlots from "./pages/ExpiredPlots";
import UnassignIMEI from "./pages/UnassignIMEI";
import TestDevice from "./pages/TestDevice";
import DeviceGraphs from "./components/DeviceGraphs";
import MeetingList from "./pages/MeetingList";
import DiseasePestObservation from "./components/DiseasePestObservation";
import SalesPlanList from "./pages/SalesPlanList";
import OrderList from "./pages/OrderList";
import FarmerProfileList from "./pages/FarmerProfileList";
import { getZammadUserByMobile } from "./services/zammad.service";
import AddMovement from "./pages/AddMovement";
import TicketsOverview from "./pages/TicketsOverview";
import PestImagesPage from "./pages/PestImages";

const firebaseConfig = {
  apiKey: "AIzaSyCgMdnraMY5PZptGrJ-390VxLpnySe1Ymg",
  authDomain: "fyllo-support-firebase.firebaseapp.com",
  projectId: "fyllo-support-firebase",
  storageBucket: "fyllo-support-firebase.appspot.com",
  messagingSenderId: "186102761519",
  appId: "1:186102761519:web:612ae31592052fba851eda",
  measurementId: "G-M3GJLBHZ22",
};

let app, messaging: any;

if (process.env.REACT_APP_ENV === "prod") {
  app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
}

async function getFirebaseToken() {
  try {
    if (!messaging) return undefined;

    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
    if (currentToken) {
      return currentToken;
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      return undefined;
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
    return undefined;
  }
}

const App: React.FC = () => {
  const [connected, setConnected] = useState(true);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [present, dismiss] = useIonToast();

  Network.addListener("networkStatusChange", (status) => {
    if (!connected && status.connected) {
      window.location.reload();
    }
    setConnected(status.connected);
  });

  const [userProfile, setUserProfile] = useRecoilState(userProfileState);
  const [zammadProfile, setZammadProfile] = useRecoilState(zammadProfileState);

  useEffect(() => {
    getUserProfile()
      .then(handleErrors)
      .then(async (data) => {
        setUserProfile(data);
        const token = await getFirebaseToken();
        if (token) {
          saveToken({
            userId: data.username,
            token: token,
          });
        }
        if (data?.mobile) {
          getZammadUserByMobile(data.mobile).then((profile: any[]) => {
            setZammadProfile(profile[0]);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [loginSuccess]);

  const [hasAccess, setHasAccess] = useState(false);
  useEffect(() => {
    canAccessCustomerData()
      .then(handleErrors)
      .then((data) => {
        setHasAccess(data.hasAccess === "yes");
      })
      .catch((err) => {
        console.log(err);
      });
  }, [loginSuccess]);

  const logout = useCallback(() => {
    logoutUser()
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  !!messaging &&
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      present({
        buttons: [{ text: "close", handler: () => dismiss() }],
        header: payload.notification?.title,
        message: payload.notification?.body,
        duration: 5000,
        position: "top",
        onDidDismiss: () => console.log("dismissed"),
        onWillDismiss: () => console.log("will dismiss"),
      });
    });

  if (!userProfile) {
    return <Login connected={connected} onSuccess={setLoginSuccess} />;
  } else if (userProfile && !hasAccess) {
    return (
      <IonPage>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <IonMenu contentId="main">
              <IonHeader>
                <IonToolbar>
                  <IonTitle>Menu</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                <IonList>
                  <IonMenuToggle>
                    <IonItem>
                      <Link
                        className="menuLink"
                        to={{ pathname: "/tabs/plots" }}
                      >
                        Home
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link className="menuLink" to={{ pathname: "/profile" }}>
                        Profile
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link
                        className="menuLink"
                        to={{ pathname: "/tabs/test-devices" }}
                      >
                        Test Devices
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem onClick={logout}>Logout</IonItem>
                  </IonMenuToggle>
                </IonList>
              </IonContent>
            </IonMenu>
            <IonRouterOutlet id="main">
              <Route
                path="/tabs/plots"
                render={(props) => <PlotList readOnlyView={true} />}
              />
              <Route exact path="/">
                <Redirect to="/tabs/plots" />
              </Route>
              <Route exact path="/tabs/plot">
                <Plot />
              </Route>
              <Route
                path="/profile"
                exact
                render={() => <Profile userProfile={userProfile} />}
              />
              <Route
                path="/sensor-data"
                exact
                render={() => <PlotSensorData />}
              />
              <Route
                path="/add-disease-pest-observation"
                exact
                render={() => <DiseasePestObservation />}
              />
              <Route
                path="/tabs/test-devices"
                exact
                render={() => <TestDevice />}
              />
              <Route
                path="/tabs/test-devices/graph"
                exact
                render={() => <DeviceGraphs />}
              />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonPage>
    );
  }

  return (
    <IonApp>
      <IonPage>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <IonMenu contentId="main">
              <IonHeader>
                <IonToolbar>
                  <IonTitle>Menu</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                <IonList>
                  <IonMenuToggle>
                    <IonItem>
                      <Link className="menuLink" to={{ pathname: "/tabs" }}>
                        Home
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link className="menuLink" to={{ pathname: "/profile" }}>
                        Profile
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link
                        className="menuLink"
                        to={{ pathname: "/referrals" }}
                      >
                        Referrals
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link className="menuLink" to={{ pathname: "/leads" }}>
                        Leads
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link className="menuLink" to={{ pathname: "/tickets" }}>
                        Tickets
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link className="menuLink" to={{ pathname: "/orders" }}>
                        Orders
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link
                        className="menuLink"
                        to={{ pathname: "/maintenances" }}
                      >
                        Maintenances
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link
                        className="menuLink"
                        to={{ pathname: "/interactions" }}
                      >
                        Conversations
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link
                        className="menuLink"
                        to={{ pathname: "/salesPlans" }}
                      >
                        Sales & Marketing
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link
                        className="menuLink"
                        to={{ pathname: "/expired-plots" }}
                      >
                        Expired Plots
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link
                        className="menuLink"
                        to={{ pathname: "/faulty-plots" }}
                      >
                        Faulty Plots
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link
                        className="menuLink"
                        to={{ pathname: "/pest-images" }}
                      >
                        Pest Images
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link
                        className="menuLink"
                        to={{ pathname: "/add-inventory" }}
                      >
                        Add Inventory
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link
                        className="menuLink"
                        to={{ pathname: "/tabs/test-devices" }}
                      >
                        Test Devices
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link className="menuLink" to={{ pathname: "/formulas" }}>
                        Formulas
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link
                        className="menuLink"
                        to={{ pathname: "/farmer-profiles" }}
                      >
                        Farmer Profiles
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link className="menuLink" to={{ pathname: "/vouchers" }}>
                        Vouchers
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                    <IonItem>
                      <Link className="menuLink" to={{ pathname: "/payment" }}>
                        Payment QR Code
                      </Link>
                    </IonItem>
                  </IonMenuToggle>
                  <IonMenuToggle>
                      <IonItem>
                        <Link
                          className="menuLink"
                          to={{ pathname: "/unassign-imei" }}
                        >
                          Unassign IMEI
                        </Link>
                      </IonItem>
                    </IonMenuToggle>
                  {["apptestadmin"].includes(
                    (userProfile as any)?.farmUserId
                  ) && (
                    <IonMenuToggle>
                      <IonItem>
                        <Link
                          className="menuLink"
                          to={{ pathname: "/pendingpayments" }}
                        >
                          Authorize Payments
                        </Link>
                      </IonItem>
                    </IonMenuToggle>
                  )}
                  <IonMenuToggle>
                    <IonItem onClick={logout}>Logout</IonItem>
                  </IonMenuToggle>
                </IonList>
              </IonContent>
            </IonMenu>
            <IonRouterOutlet id="main">
              <Route path="/tabs" component={MainTabs} />
              <Route exact path="/">
                <Redirect to="/tabs" />
              </Route>
              <Route
                path="/profile"
                exact
                render={() => <Profile userProfile={userProfile} />}
              />
              <Route path="/referrals" exact render={() => <ReferralList />} />
              <Route path="/leads" exact render={() => <LeadList />} />
              <Route path="/tickets" exact render={() => <TicketList />} />
              <Route path="/meetings" exact render={() => <MeetingList />} />
              <Route
                path="/salesPlans"
                exact
                render={() => <SalesPlanList />}
              />
              <Route path="/orders" exact render={() => <OrderList />} />
              <Route
                path="/tickets-overview"
                exact
                render={() => <TicketsOverview />}
              />
              <Route
                path="/maintenances"
                exact
                render={() => <MaintenanceList />}
              />
              <Route
                path="/interactions"
                exact
                render={() => <InteractionList />}
              />
              <Route
                path="/expired-plots"
                exact
                render={() => <ExpiredPlots />}
              />
              <Route
                path="/faulty-plots"
                exact
                render={() => <FaultyPlots />}
              />
              <Route
                path="/pest-images"
                exact
                render={() => <PestImagesPage />}
              />
              <Route
                path="/add-inventory"
                exact
                render={() => <AddMovement />}
              />

              <Route path="/formulas" exact render={() => <FormulaList />} />
              <Route
                path="/farmer-profiles"
                exact
                render={() => <FarmerProfileList />}
              />
              <Route path="/vouchers" exact render={() => <VoucherList />} />
              <Route
                path="/pendingpayments"
                exact
                render={() => <PendingPaymentList />}
              />
              <Route path="/payment" exact render={() => <PaymentQRCode />} />
              <Route
                path="/outlier-details"
                exact
                render={() => <OutlierPlotDetails />}
              />
              <Route
                path="/sensor-data"
                exact
                render={() => <PlotSensorData />}
              />
              <Route
                path="/add-disease-pest-observation"
                exact
                render={() => <DiseasePestObservation />}
              />
              <Route
                path="/unassign-imei"
                exact
                render={() => <UnassignIMEI />}
              />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonPage>
    </IonApp>
  );
};

export default App;
