import React, { useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonBackButton,
  IonText,
  IonButton,
  IonToast,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonThumbnail,
  IonModal,
} from "@ionic/react";
import { Camera, CameraResultType } from "@capacitor/camera";
import { uploadFile } from "../services/image.service";
import { useRecoilValue } from "recoil";
import { userProfileState } from "../services/state.service";
import ImageViewer from "../components/ImageViewer";

const PestImagesPage: React.FC = () => {
  const userProfile: any = useRecoilValue(userProfileState);
  const [error, setError] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState<"success" | "danger">("success");
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);

  const handleOpenCamera = async () => {
    try {
      setError(null);
      const photo = await Camera.getPhoto({
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
      });

      if (photo?.dataUrl) {
        const imageFile = await fetch(photo.dataUrl)
          .then((res) => res.blob())
          .then((blob) => new File([blob], "pest_image.jpg", { type: "image/jpeg" }));

        await handleUpload(imageFile);
      }
    } catch (err) {
      setToastMessage("Failed to capture image. Please try again.");
      setToastColor("danger");
      setShowToast(true);
    }
  };
  const handleUpload = async (file: File) => {
    setUploading(true);
    try {
      const formdata = new FormData();
      formdata.append("file", file);

      const response = await uploadFile("Pest_trap_Training", userProfile.username, file);

      if (response.ok) {
        const data = await response.json();
        setUploadedImages([...uploadedImages, data]);
        setToastMessage("Image uploaded successfully!");
        setToastColor("success");
      } else {
        const errorResponse = await response.json();
        console.error("Upload error:", errorResponse);
        setToastMessage(errorResponse.message || "Failed to upload image. Please try again.");
        setToastColor("danger");
      }
    } catch (err) {
      console.error("Error during upload:", err);
      setToastMessage("An error occurred during image upload.");
      setToastColor("danger");
    } finally {
      setUploading(false);
      setShowToast(true);
    }
  };

  function deleteImage(url: string) {
    const imageIndex = uploadedImages.findIndex(
      (item) => item.images[0] === url
    );
    if (imageIndex > -1) {
      uploadedImages.splice(imageIndex, 1);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/home" />
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Pest Images</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="ion-padding">
          <IonText>
            <p>Click the button below to capture and upload pest images.</p>
          </IonText>
          <IonButton
            expand="block"
            color="primary"
            onClick={handleOpenCamera}
            disabled={uploading}
          >
            {uploading ? "Uploading..." : "Open Camera"}
          </IonButton>
          {error && (
            <IonText color="danger">
              <p>{error}</p>
            </IonText>
          )}
        </div>
        {!!uploadedImages.length && (
          <>
            <IonItem lines="none">
              <IonLabel>Recently uploaded Images</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonGrid>
                <IonRow>
                  {uploadedImages?.map(
                    (item: any, index: number) => (
                      <IonCol key={index}>
                        <IonThumbnail>
                          <img
                            src={item?.thumbnails && item.thumbnails[0]}
                            onClick={(e) => {
                              setSelectedImage(
                                item?.images && item.images[0]
                              );
                              setShowImageModal(true);
                            }}
                          />
                        </IonThumbnail>
                      </IonCol>
                    )
                  )}
                </IonRow>
              </IonGrid>
            </IonItem>
          </>
        )}
      </IonContent>

      <IonModal isOpen={showImageModal} cssClass="my-custom-class">
        <ImageViewer
          imageUrl={selectedImage}
          onDeleteHandler={deleteImage}
          onCloseHandler={setShowImageModal}
        />
      </IonModal>
      
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        color={toastColor}
      />
    </IonPage>
  );
};

export default PestImagesPage;
