import { IonBackButton, IonButtons, IonCard, IonCardHeader, IonCardSubtitle, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonSpinner, IonTitle, IonToolbar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getDeviceGraphs, searchDevices } from "../services/device.service";
import moment from "moment";
import TimeSeriesChart from "./TimeSeriesChart";
import { getChartData } from "./PlotSensorData";

const fields: string[] = [
  'soilMoisture1',
  'soilMoisture2',
  'soilTemp',
  'airTemp',
  'airHumidity',
  'airPressure',
  'baroPressure',
  'airTemp1',
  'airHumidity1',
  'airPressure1',
  'baroPressure1',
  'leafWetness',
  'rainFall',
  'lightIntensity',
  'lightIntensity1',
  'battery',
  'windSpeed'
];

const DeviceGraphs: React.FC = () => {

  const location = useLocation();
  const [deviceData, setDeviceData] = useState<any>();
  const [deviceGraphs, setDeviceGraphs] = useState<any[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sinceHours, setSinceHours] = useState<number>(240);

  const [device, setDevice] = useState({ id: '', imei: '' });
  
  useEffect(() => {
    if (location.state) {
      const locationState = location.state as any;
      const deviceId = locationState.deviceId ?? '';
      const imei = locationState.imei ?? '';
      if (deviceId) {
        getGraphs(deviceId);
        getDeviceData(deviceId);
        setDevice({
          id: deviceId,
          imei
        });
      }
    }
  }, [location])

  useEffect(() => {
    if(!isLoading){
      getGraphs(device.id);
    }
  }, [sinceHours])

  const getDeviceData= async (deviceId: string) => {
    const devices = await searchDevices(deviceId, 0, 10);
    setDeviceData(devices[0]);
  }

  const getGraphs = async (deviceId: string) => {
    setIsLoading(true);
    const timestamp = moment().subtract(sinceHours, 'hours').toDate();
    const graphs = await getDeviceGraphs(deviceId, timestamp);
    setDeviceGraphs(graphs);
    setIsLoading(false);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Device Graphs</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonItem>
          <IonLabel position="stacked">IMEI</IonLabel>
          <IonInput readonly id="farmId" name="farmId" value={device.imei} ></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Plot ID</IonLabel>
          <IonInput id="plotId" name="plotId" readonly value={deviceData?.plotId} placeholder="Plot ID" ></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Batch ID</IonLabel>
          <IonInput id="batchId" name="batchId" readonly value={deviceData?.batchId} placeholder="Batch ID" ></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">Since Hours (1-720 hrs)</IonLabel>
          <IonInput id="device-graphs-sinceHours" name="sinceHours" type="number" inputmode="numeric" value={sinceHours} placeholder="Default is 24 hrs" debounce={1000} onIonChange={(e: any) => setSinceHours(e.target.value ?? 24)}></IonInput>
        </IonItem>

        {
          isLoading && <div style={{  width: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', paddingTop: '20px' }} >
            <IonSpinner />
          </div>
        }


        <IonList>
          {
            (!!deviceGraphs && deviceGraphs.length > 0 && !isLoading) ? (
              <>
                {fields.map((field: string, index: number) => {
                  if (deviceGraphs[0] && deviceGraphs[0][field] !== null && deviceGraphs[0][field] !== undefined) {
                    return (
                      <IonCard id={field} key={`device-graph-${index}-${field}`}>
                        <IonCardHeader>
                          <IonCardSubtitle>{field}</IonCardSubtitle>
                        </IonCardHeader>
                        <TimeSeriesChart
                          format={'date'}
                          chartData={getChartData(deviceGraphs, field)}
                        ></TimeSeriesChart>
                      </IonCard>
                    )
                  } 
                  return <span key={`device-graph-${index}-${field}`}></span>
                })
                }
              </>
            ) : (
                <div style={{ width: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', paddingTop: '20px' }} >
                <IonLabel>{ isLoading ? 'Loading...' : 'No Graph Found' }</IonLabel>
              </div>
            )
          }

        </IonList>
      </IonContent>
    </IonPage>
  )
}

export default DeviceGraphs;